@import 'variables';

@media all and (max-width:$lg-width) {

  .footer {

    padding:36px 0 33px;

    &-inner {
      padding:0 65px 3px;
    }

    &-menu {

      margin:25px 230px 0 150px;

      li {
        font-size:20px;

        margin-left:30px;

        a {

          &:after {
            bottom:2px;
          }
        }
      }
    }

    &-language-new {

      a {

        line-height:52px;

        .icon {
          width:52px;
          height:52px;

          margin-right:15px;
        }

        span {
          font-size:20px;
          line-height:22px;

          max-width:150px;
        }
      }
    }

    &-contacts {
      width:110px;

      &__title {
        font-size:20px;
        line-height:23px;

        padding:0 0 10px;
      }

      .socials {
        margin-left:-20px;

        -webkit-transform:scale(.7);
        -moz-transform:scale(.7);
        transform:scale(.7);

        .icon {
          margin:0 10px;

          &:first-of-type {
          }

          &:last-of-type {
          }
        }
      }
    }
  }
}

@media all and (max-width:$sm-width) {

  .footer {

    &-inner {
      padding-right:0;
    }

    &-menu {
      display:inline-block;
      vertical-align:top;
      white-space:normal;
      text-align:left;

      margin:0 0 0 95px;
      float:none;

      li {
        display:block;
        margin:19px 0 0;

        font-size:20px;
        line-height:1;

        &:first-of-type {
          margin-top:0;
        }
      }
    }

    &-contacts {

      float:none;
      width:170px;

      display:inline-block;
      vertical-align:top;

      &__title {
      }

      .socials {
        margin:0;

        -webkit-transform:none;
        -moz-transform:none;
        transform:none;
      }
    }

    &-language-new {

      position:absolute;
      bottom:0;
      left:0;

      margin:0 0 0 82px;
    }

    /*&-language {
      width:170px;

      position:absolute;
      bottom:0;
      left:0;

      margin-left:65px;

      &__title {
        padding-bottom:10px;

        .first-line {
          display:none;
        }
      }

      &__list {

        a {
        }
      }
    }*/
  }
}

@media all and (max-width:$xs-width) {

  .footer {

    padding:20px 0 23px;

    &-inner {
      padding:0 0 0 20px;
    }

    &-menu {
      margin-left:35px;

      li {
        font-size:12px;

        margin-top:12px;

        a {

          &:after {
            bottom:0;
          }
        }
      }
    }

    &-contacts {

      width:auto;
      margin:0 0 50px 0;

      &__title {
        font-size:15px;
        line-height:17px;
      }
    }

    &-language-new {

      margin-left:20px;

      a {

        line-height:25px;

        .icon {
          width:25px;
          height:25px;

          border-width:1px;
          margin-right:8px;
        }

        span {
          font-size:12px;
          line-height:14px;

          max-width:90px;
        }
      }
    }

    /*&-language {
      width:113px;

      margin-left:20px;

      &__title {
        font-size:12px;
        line-height:1;

        padding:0 0 7px;
      }

      &__list {

        a {
          padding:0 5px;

          font-size:9px;
          line-height:14px;
        }
      }
    }*/
  }
}