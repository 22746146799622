@import 'variables';

@media all and (max-width:$lg-width) and (min-width:$sm-width) {

  .socials {

    -webkit-transform:scale(.8);
    -moz-transform:scale(.8);
    transform:scale(.8);

    .icon {
      margin:0 10px;

      &:first-of-type {
        margin:0 10px;
      }

      &:last-of-type {
        margin-right:0;
      }
    }

    .socials-ext-link {
      font-size:21px;
      line-height:27px;

      margin-top:20px;
    }
  }

  .app-buttons {

    padding:40px 0 0 155px;

    a {
      height:90px;

      img {
        max-height:100%;
      }
    }
  }
}

@media all and (max-width:$sm-width) {
  .br {
    display:none;

    &-sm {
      display:block;
    }
  }

  .socials-sm {
    display:block;
  }

  .css-fixed-header {

    .strip-1 {

      &:after {
        top:213px;
      }

      .app-buttons_wide {
        visibility:hidden;
        margin-bottom:-40px;
      }
    }
  }
}

@media all and (max-width:$xs-width) {

  .socials {

    line-height:21px;

    &_wide {

      padding:10px 0;

      .socials-ext-link {
        font-size:14px;
        line-height:21px;

        margin-left:17px;
      }
    }

    .icon {
      margin-left:15px;

      &:first-of-type {
        margin-left:0;
      }
    }
  }

  .css-fixed-header {

    .strip-1 {

      &:after {
        top:auto;
      }

      .app-buttons_wide {
        margin-bottom:22px;
      }
    }
  }
}