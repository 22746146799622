@import 'variables';

@media all and (max-width:$lg-width) and (min-width:$sm-width) {

  .header {
    padding:40px 0 20px 155px;

    .logo {
      height:64px;

      margin-left:-88px;
    }
  }
}

@media all and (max-width:$sm-width) {

  .header {
    text-align:center;

    padding:35px 0;
    width:auto;

    .logo {
      margin:0;
    }

    &_global {

      text-align:center;

      .socials {
        display:none;
      }
    }
  }

  .strip {
    .header {
      .socials {
        display:none;
      }
    }
  }
}

@media all and (max-width:$xs-width) {

  .header {

    .logo {
      width:80%;
      max-width:400px;
    }

    .app-buttons {

      &_wide {
        display:block;

        padding:13px 0;
        margin:35px 0 15px;

        a {
          width:auto;
          height:50px;
          margin:0 4px;

          img {
            width:auto;
            height:100%;
          }
        }
      }
    }
  }
}