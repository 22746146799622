a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, dialog, div, dl, dt, em, embed, fieldset, figcaption, figure, font, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, hr, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, meter, nav, object, ol, output, p, pre, progress, q, rp, rt, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video, xmp, input, textarea, button {
  border:0;
  font-size:100%;
  margin:0;
  padding:0
}

html, body {
  height:100%;
  min-width:320px;
  position:relative;

  line-height:1.42857143;
  font-size:14px;

  background:#000;
  color:#fff;
}

html, body,
input, textarea, button {
  font-family:OpenSans, sans-serif;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display:block;
}

b, strong {
  font-weight:bold;
}

img {
  color:transparent;
  font-size:0;
  vertical-align:middle;
  -ms-interpolation-mode:bicubic
}

li {
  display:list-item;
}

table {
  border-collapse:collapse;
  border-spacing:0;
}

th, td, caption {
  font-weight:normal;
  vertical-align:top;
  text-align:left;
}

svg {
  overflow:hidden;
}

input, textarea, button, a {
  outline:none;
  resize:none;
}

* {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box
}

ol, ul, li {
  list-style:none inside;
}

a, .btn-link {
  text-decoration:underline;
  cursor:pointer;
  color:#2290d8;
}

a:hover, a:focus, .btn-link:hover, .btn-link:focus {
  text-decoration:underline;
  color:#2290d8;
}

a:active, a:hover, .btn-link:hover, .btn-link:active {
  outline:0;
}

.btn-link {
  background:none;
}

.fll {
  float:left;
}

.flr {
  float:right;
}

.pr {
  position:relative;
}

.tac {
  text-align:center;
}

.ovh {
  overflow:hidden;
}

.clfix {
  display:block;
  clear:both;
  float:none;

  width:100%;
  height:0;
  margin:0;
  padding:0;

  position:relative;
  font:0/0 d;
}

.br {
  font:0/0 d;
  display:block;

  &-sm {
    display:none;
  }
}