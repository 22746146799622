@import 'variables';

.icon {
  display:inline-block;
  vertical-align:top;

  font-size:0;
  line-height:0;

  background:0 0 no-repeat;
  -webkit-background-size:cover;
  background-size:cover;

  &-fb {
    width:19px;
    height:39px;

    background-image:url(imgs/icon-fb.png);

    &_w {
      background-image:url(imgs/icon-fb-white.png);
    }

    &:hover {
      background-image:url(imgs/icon-fb-white_hover.png);
    }
  }

  &-inst {
    width:40px;
    height:39px;

    background-image:url(imgs/icon-inst.png);

    &_w {
      background-image:url(imgs/icon-inst-white.png);
    }

    &:hover {
      background-image:url(imgs/icon-inst-white_hover.png);
    }
  }

  &-tw {
    width:38px;
    height:31px;

    background-image:url(imgs/icon-tw.png);

    &_w {
      background-image:url(imgs/icon-tw-white.png);
    }

    &:hover {
      background-image:url(imgs/icon-tw-white_hover.png);
    }
  }
}

@import 'icons-langs';
@import 'icons-zmedia';