@import 'variables';

$countries:anguilla antigua-barbuda argentina armenia australia bahamas bahrain barbados belgique belize bermuda blgaria bolivia botswana botswana brasil bvi cameroun canada cayman-islands centrafricaine cesko chile china colombia costa-rica cotedivoire danmark deutschland dominica dominicana ecuador eesti egypt el-salvador equatorial-guinea espana france greece grenada guatemala guinea-bissau guinee guyana honduras hong-kong hrvatska india indonesia ireland israel italia jamaica japan jordan kenia korea kuwait latvija liechtenstein lietuva luxembourg macao macedonia madagascar magyarorszag malaysia mali malta maroc maurice mexico moldova montenegro montserrat mozambique nederland new-zealand nicaragua niger nigeria norge oman osterreich panama paraguay peru philippines polska portugal puerto-rico qatar romania rus saudi-arabia schweiz senegal singapore slovenia slovensko south-africa st-kitts st-lucia st-vincent suisse suomi suriname sverige taiwan thailand trinidad tunisie turkiye turks uganda uk united-arab-emirates uruguay usa venezuela vietnam;

.icon {

  &-language {
    width:82px;
    height:82px;

    background-position:center center;
    -webkit-background-size:84px;
    background-size:84px;

    @each $country in $countries {
      &-#{$country} {
        background-image:url('imgs/langs/#{$country}.png');
      }
    }
  }
}

@import 'icons-zmedia';