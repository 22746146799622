@import 'variables';

@media all and (max-width:$lg-width) and (min-width:$sm-width) {
  $lg-left-padding:155px;

  .strip {

    &-logo {
      margin-left:67px;

      height:64px;
    }

    &-title {

      font-size:110px;
      line-height:110px;
      letter-spacing:0;

      .text {
        font-size:133px;
        letter-spacing:-5px;

        top:12px;
      }
    }

    &-content {

      .content {
        font-size:28px;
        line-height:29px;
      }
    }

    ///////
    &-1 {
      padding-bottom:40px;

      &:before {
        background-image:url(imgs/screen-1l-md.png);
      }

      &:after {
        background-image:url(imgs/screen-1r-md.png);
        -webkit-background-size:contain;
        background-size:contain;
      }

      .strip {

        &-inner {
          width:77%;
        }

        &-content {
          margin:35px 0 0 $lg-left-padding;
        }

        &-title {
          padding-left:$lg-left-padding;

          line-height:100px;
        }
      }
    }

    ///////
    &-2 {

      padding:140px 0 53px;

      .strip-logo {
        margin-top:-80px;
      }

      &:before {
        display:none;
      }

      &:after {
        background-image:url(imgs/screen-2r-md.png);
      }

      .strip {

        &-title {
          margin:0 180px 40px -180px;
        }

        &-content {
          margin:0 0 0 $lg-left-padding;
        }
      }
    }

    ///////
    &-3 {

      padding:90px 0 57px;

      .strip-logo {
        margin-left:0;
        margin-right:67px;
        margin-top:-25px;
      }

      &:before {
        background-image:url(imgs/screen-3l-md.png);
      }

      &:after {
        background-image:url(imgs/screen-3r-md.png);
      }

      .strip {

        &-title {
        }

        &-content {
          margin:70px 196px 0 0;
          width:63%;
        }
      }
    }

    ///////
    &-4 {

      padding:150px 0 23px;

      .strip-logo {
        margin-top:-115px;
      }

      &:before {
        background-image:url(imgs/screen-4l-md.png);
      }

      &:after {
        background-image:url(imgs/screen-4r-md.png);
      }

      .strip {

        &-title {
          padding-left:$lg-left-padding;

          .text {
            font-size:284px;
            line-height:230px;

            i {
              font-size:110px;
              line-height:110px;
              letter-spacing:0;
            }
          }
        }

        &-content {
          margin:0 0 0 $lg-left-padding;
          width:40%;
        }
      }
    }

    ///////
    &-5 {

      padding:200px 0 50px;

      .strip-logo {
        margin-top:-140px;
      }

      &:before {
        background-image:url(imgs/screen-5l-md.png);
      }

      &:after {
        background-image:url(imgs/screen-5r-md.png);
      }

      .app-buttons {
        padding-top:50px;

        a {
          height:100px;

          img {
            max-height:100%;
          }
        }
      }

      .strip {

        &-title {

          padding-left:$lg-left-padding;
        }
      }
    }
  }
}

@media all and (max-width:$sm-width) {

  .strip {

    padding:0 25px;

    border-bottom-width:3px;

    &-logo {
      display:none;
    }

    &-title {
      font-size:62px;
      line-height:65px;
      letter-spacing:0;

      .text {
        font-size:75px;

        top:10px;
      }
    }

    &-content {
      margin:0;
      padding:14px 20px 0;

      .content {
        font-size:20px;
        line-height:22px;
        letter-spacing:0;
      }
    }

    ///////
    &-1 {

      &:before {
        display:none;
      }

      &:after {
        background-image:url(imgs/screen-1r-sm.png);

        top:289px;
      }

      .app-buttons {
        display:none;

        &_wide {
          display:block;

          margin:0 -25px 40px;
        }
      }

      .strip {
        &-content {
          width:75%;
          margin:30px 0 34px;

          .content {
            font-size:20px;
            line-height:22px;
          }
        }

        &-title {
          .text {
            top:0;
          }
        }

        &-inner {
        }
      }
    }

    ///////
    &-2 {

      padding-top:30px;
      padding-bottom:40px;

      &:before {
        display:none;
      }

      &:after {
        background:url(imgs/screen-2r-sm.png) top right no-repeat;
      }

      .strip {
        &-content {
          width:95%;
        }

        &-title {
          margin:0;

          .text {
            top:5px;
          }

          .last-line {
            margin:0;
          }
        }

        &-inner {
          width:66%;
        }
      }
    }

    ///////
    &-3 {

      padding:45px 25px 40px 0;
      margin:0 0 0 -25px;

      &:before {
        background:url(imgs/screen-3l-sm.png) left bottom no-repeat;
      }

      &:after {
        display:none;
      }

      .strip {
        &-content {
          margin-top:35px;
          width:69%;
        }

        &-title {
          .text {
            top:5px;
          }

          .last-line {
          }
        }

        &-inner {
          width:59%;
          margin:0;
        }
      }
    }

    ///////
    &-4 {

      padding:40px 0 35px 25px;

      &:before {
        display:none;
      }

      &:after {
        background:url(imgs/screen-4r-sm.png) right bottom no-repeat;
      }

      .strip {
        &-content {
          margin-top:40px;
          width:50%;

          border-bottom:none;

          position:relative;
          overflow:hidden;

          &:after {
            content:'';

            display:block;
            width:70%;
            height:1px;

            position:absolute;
            bottom:0;
            left:0;

            background:#fff;
          }
        }

        &-title {
          .text {
            font-size:169px;
            line-height:137px;

            top:7px;
            left:-7px;

            i {
              font-size:62px;
              line-height:1;

              position:relative;
              margin-left:-81px;
            }
          }

          .last-line {
          }
        }

        &-inner {
        }
      }
    }

    ///////
    &-5 {
      padding:40px 25px;

      &:before {
        display:none;
      }

      &:after {
        background:url(imgs/screen-5r-sm.png) right top no-repeat;
      }

      .strip {
        &-content {
          margin-top:35px;
          width:69%;
        }

        &-title {
          .text {
            top:5px;
          }
        }

        &-inner {
          width:auto;
          margin:0;
        }
      }

      .app-buttons {
        display:none;
      }
    }
  }
}

@media all and (max-width:$xs-width) {
  .strip {

    padding:0 15px;

    .header {
      margin:0 -15px;
    }

    &-content {
      display:none;
    }

    &-title {
      font-size:31px;
      line-height:32px;

      .text {
        font-size:37px;
        line-height:32px;
      }

      .sp:after {
        letter-spacing:-3px;
      }
    }

    ///////
    &-1 {

      padding-bottom:80px;

      &:before {
        display:none;
      }

      &:after {
        background:url(imgs/screen-1r-xs.png) bottom right no-repeat;

        top:auto;
        bottom:0;
      }

      .app-buttons {

        &_wide {
          padding:13px 0;
          margin:0 -15px 80px;

          a {
            width:40%;
            margin:0 4px;

            img {
              width:100%;
            }
          }
        }
      }

      .strip {

        &-title {
          .text {
            top:2px;
          }
        }

        &-inner {
        }
      }
    }

    ///////
    &-2 {
      padding:60px 0 65px;

      &:before {
        display:none;
      }

      &:after {
        background-image:url(imgs/screen-2r-xs.png);
      }

      .strip {

        &-title {
          .text {
            top:2px;
          }
        }

        &-inner {
          width:63%;
        }
      }
    }

    ///////
    &-3 {
      padding:80px 0;

      &:before {
        background-image:url(imgs/screen-3l-xs.png);
      }

      &:after {
        display:none;
      }

      .strip {

        &-title {
          .text {
            top:2px;
          }
        }

        &-inner {
        }
      }
    }

    ///////
    &-4 {
      padding:80px 15px;

      &:before {
        display:none;
      }

      &:after {
        background-image:url(imgs/screen-4r-xs.png);
      }

      .strip {

        &-title {
          .text {
            font-size:84px;
            line-height:69px;

            top:0;
            left:-7px;

            i {
              font-size:31px;
              line-height:1;

              position:relative;
              margin:5px 0 0 -40px;
            }
          }

          .last-line {
          }
        }

        &-inner {
        }
      }
    }

    ///////
    &-5 {
      padding:20px 15px;

      &:before {
        display:none;
      }

      &:after {
        background-image:url(imgs/screen-5r-xs.png);
      }

      .strip {

        &-title {
          .text {
            top:2px;
          }
        }

        &-inner {
        }
      }
    }
  }
}