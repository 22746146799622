@import 'variables';

.faq {

  &-item {

    position:relative;
    margin:0 0 45px;

    border-bottom:1px solid #fff;

    &__title,
    &__number {
      font-family:RockwellCondensed, serif;
      text-transform:uppercase;
      font-size:67px;
      line-height:69px;
      font-weight:400;
    }

    &__title {
      display:block;

      padding:0 0 30px 60px;
      position:relative;

      text-decoration:none;
      color:#fff;

      &:hover {
        text-decoration:none;
      }

      &:before {
        content:'';

        position:absolute;
        top:0;
        left:0;

        width:154px;
        height:86px;

        background:url(imgs/faq-1.png) 0 0 no-repeat;
        font:0/0 d;

        z-index:-1;

        margin:10px 0 0 -60px;
      }
    }

    &__number {
      position:absolute;
      top:0;
      left:0;
    }

    &__desc {
      font-size:31px;
      line-height:36px;

      padding:0 0 35px 20px;
    }

    &_full {

      .faq-item {
      }
    }

    @for $i from 1 through 4 {

      &-#{$i} {

        .faq-item__title {

          &:before {
            background-image:url('imgs/faq-#{$i}.png');
          }
        }
      }
    }
  }

  &-wrapper {
    height:135px;
  }
}

@import 'faq-zmedia';