@import 'variables';

.rules-page {
  .body-wrapper {
    background:#000 url(imgs/rules-bg.png) 0 50% no-repeat;
    -webkit-background-size:contain;
    background-size:contain;
  }
}

.rules {

  font-family:RockwellCondensed, serif;
  text-transform:uppercase;

  padding:0 0 230px;

  &-header {
    text-align:center;
    font-size:157px;
    line-height:1;

    border-bottom:2px solid #fff;

    padding:0 0 35px;
    //margin:0 0 40px;

    span {
      @extend .pr;

      font-size:190px;

      display:inline-block;
      vertical-align:top;
    }
  }

  &-item {
    @extend .pr;

    border-bottom:2px solid #fff;
    padding:35px 20px 35px 275px;

    &__number {
      display:block;

      position:absolute;
      top:50%;
      left:0;

      margin-top:-78px;

      font-size:157px;
      line-height:1;

      i {
        @extend .pr;

        display:inline-block;
        vertical-align:top;

        font-style:normal;
        font-size:73px;
        line-height:1;

        top:38px;
        left:-12px;

        -webkit-transform:rotate(-90deg);
        -moz-transform:rotate(-90deg);
        transform:rotate(-90deg);
      }
    }

    &__desc {
      font-size:103px;
      line-height:99px;
      letter-spacing:-5px;
    }
  }
}

@import 'rules-zmedia';