@import 'variables';

@media all and (max-width:$lg-width) {

  .language-list {

    margin-left:50px;
    margin-right:50px;

    h1 {
      font-size:56px;
      line-height:58px;
    }

    h3 {
      font-size:40px;
      vertical-align:top;

      padding-bottom:20px;
      margin-top:75px;

      &:first-of-type {
        margin-top:50px;
      }
    }

    a {

      line-height:52px;

      margin:27px 0;

      span {
        font-size:20px;
        line-height:22px;

        max-width:200px;
      }
    }
  }
}

@media all and (max-width:$sm-width) {

  .language-list {

    a {
      width:33.3%;
    }
  }
}

@media all and (max-width:$sm-768-width) {

  .language-list {

    a {
      width:50%;
    }
  }
}

@media all and (max-width:$xs-width) {

  .language-list {

    margin-left:20px;
    margin-right:20px;

    h1 {
      font-size:31px;
      line-height:32px;
    }

    h3 {
      font-size:20px;
      line-height:22px;
    }

    a {
      width:100%;
    }
  }
}