@import 'variables';

@media all and (max-width:$lg-width) {
  .icon {

    &-language {
      width:52px;
      height:52px;

      -webkit-background-size:54px;
      background-size:54px;
    }
  }
}

@media all and (max-width:$xs-width) {
  .icon {

    &-fb {
      width:9px;
      height:19px;
    }

    &-inst {
      width:20px;
      height:19px;
    }

    &-tw {
      width:19px;
      height:16px;
    }
  }
}