@import 'variables';

.language-list {

  font-size:0;
  line-height:0;

  h1 {
    font-family:RockwellCondensed, serif;
    text-transform:uppercase;
    text-align:center;
    font-weight:400;
    font-size:67px;
    line-height:1;
  }

  h3 {
    font-family:RockwellCondensed, serif;
    text-transform:uppercase;
    font-weight:400;
    font-size:46px;
    line-height:1;

    border-bottom:2px solid #fff;

    margin-top:170px;
    padding:0 0 37px;

    &:first-of-type {
      margin-top:80px;
    }
  }

  a {
    display:inline-block;
    vertical-align:top;

    width:25%;

    font-size:0;
    line-height:82px;
    white-space:nowrap;
    color:#fff;

    margin:40px 0 50px;

    .icon {
      border:2px solid #fff;
      vertical-align:top;

      @include border-radius(41px);

      margin-right:15px;
    }

    span {
      display:inline-block;
      vertical-align:middle;
      text-transform:uppercase;

      max-width:230px;

      font-size:31px;
      line-height:35px;
      font-weight:600;
      white-space:normal;
    }

    &:hover {

      .icon {
        border-color:#747474;
      }

      color:#747474;
    }
  }

  .text {

    &-red {
      color:#ff8b8a;
    }

    &-yellow {
      color:#ffcd5d;
    }

    &-green {
      color:#4ad082;
    }

    &-pure {
      color:#c4a7de;
    }

    &-blue {
      color:#4ed0ea;
    }
  }
}

@import 'langs-zmedia';