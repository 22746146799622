@import 'variables';

@media all and (max-width:$lg-width) {

  .faq {
    padding:0 20px;

    &-item {

      &__title {
        font-size:62px;
        line-height:65px;
      }
    }
  }
}

@media all and (max-width:$sm-width) {
  .faq-page {
    .body-wrapper {
      background:#000;
    }
  }
}

@media all and (max-width:$xs-width) {

  .faq {

    &-item {

      &__title,
      &__number {
        font-size:31px;
        line-height:32px;
      }

      &__title {
        padding:0 0 10px 30px;
      }

      &__desc {
        font-size:20px;
        line-height:26px;

        padding-left:5px;
      }
    }

    &-wrapper {
      height:70px;
    }
  }
}