@import 'variables';

.body-wrapper {
  background:#000;
}

.main-wrapper {
  @extend .pr;

  max-width:1468px;
  margin:0 auto;

  z-index:5;
}

.content {
  font-size:41px;
  line-height:43px;
  font-weight:400;

  p {
    margin:0 0 15px;
  }
}

.text {

  &-red {
    color:#eb4d3b;
  }

  &-yellow {
    color:#fafcb2;
  }

  &-pink {
    color:#f59dd6;
  }

  &-blue {
    color:#2290d8;
  }

  &-light-blue {
    color:#44a9c0;
  }

  &-turquoise {
    color:#90e3f2;
  }

  &-green {
    color:#8ed9b5;
  }

  &-purple {
    color:#693ca9;
  }

  &-light-purple {
    color:#d88cd3;
  }
}

.app-buttons {
  text-align:center;
  font:0/0 d;

  a {
    display:inline-block;
    vertical-align:top;

    margin:0 11px;

    img {
      visibility:hidden;
    }

    &.ios {
      background:url(imgs/ios-store.png) 0 0 no-repeat;
      -webkit-background-size:cover;
      background-size:cover;

      &:hover {
        background-image:url(imgs/ios-store-hover.png);
      }

      &.small {
        background-image:url(imgs/ios-store-sm.png);

        &:hover {
          background-image:url(imgs/ios-store-sm-hover.png);
        }
      }
    }

    &.google {
      background:url(imgs/google-store.png) 0 0 no-repeat;
      -webkit-background-size:cover;
      background-size:cover;

      &:hover {
        background-image:url(imgs/google-store-hover.png);
      }

      &.small {
        background-image:url(imgs/google-store-sm.png);

        &:hover {
          background-image:url(imgs/google-store-sm-hover.png);
        }
      }
    }
  }

  &_wide {
    display:none;

    background:#fff;

    border-bottom:3px solid #000;

    a {
      margin-bottom:17px;
    }
  }
}

.socials {
  @extend .tac, .flr;

  width:180px;

  font-size:0;
  line-height:39px;
  white-space:nowrap;

  &-sm {
    display:none;
  }

  .icon {
    display:inline-block;
    vertical-align:middle;

    margin-left:30px;

    &:hover {
      text-decoration:none;
    }

    &:first-of-type {
      margin-left:0;
    }
  }

  &-ext-link {
    display:block;

    text-transform:uppercase;
    text-decoration:underline;
    white-space:normal;
    line-height:39px;
    font-weight:700;
    font-size:31px;
    color:#2290d8;

    margin-top:30px;

    &:hover {
      color:#fff;
    }
  }

  &_wide {
    display:none;
    width:auto;
    float:none;

    background:none;
    border-bottom:3px solid #fff;

    margin:0;
    padding:20px 0;

    .socials-ext-link {
      display:inline-block;
      vertical-align:top;

      margin:0 0 0 40px;

      font-size:29px;
      line-height:39px;
    }
  }
}

.css-fixed-header {

  .js-static-header {
    visibility:hidden;
  }

  .js-fixed-header {
    display:block;
  }

  .strip-1 {

    .header {
      visibility:hidden;
    }
  }
}

.faq-page {

  .body-wrapper {
    background:#000 url(imgs/faq-bg.png) 100% 380px no-repeat;
  }
}

@import 'common-zmedia';