@import 'variables';

.strip {
  @extend .pr, .ovh;

  padding:0 0 90px;

  border-bottom:6px solid #fff;

  .header {
    width:75%;
  }

  .app-buttons {
    padding-top:85px;

    &_wide {
      padding:17px 0 0;
    }
  }

  &-wrapper {
    @extend .main-wrapper;

    &:after {
      content:'';

      display:block;
      clear:both;
    }
  }

  &-title {
    font-family:RockwellCondensed, serif;
    text-transform:uppercase;
    letter-spacing:-5px;
    line-height:155px;
    font-size:157px;
    font-weight:400;

    .text {
      @extend .pr;

      font-size:190px;

      top:24px;
    }

    .sp:after {
      content:' ';
      letter-spacing:-12px;
    }
  }

  &-content {
    border-top:1px solid #fff;
    border-bottom:1px solid #fff;

    padding:20px 20px 5px;
    margin:70px 0 0;

    .content {
    }
  }

  &:before, &:after {
    content:'';
    font:0/0 d;

    pointer-events:none;

    display:block;
    width:100%;
    height:100%;

    top:0;
    bottom:0;

    position:absolute;

    background:no-repeat left center;
  }

  &:after {
    left:auto;
    right:0;

    background:no-repeat right center;
  }

  //////////
  &-1 {

    padding-bottom:88px;

    &:before {
      background-image:url(imgs/screen-1l.png);
    }

    &:after {
      background:url(imgs/screen-1r.png) top right no-repeat;
    }

    .strip {
      &-inner {
        width:75%;
      }

      &-title {
        .text {
        }
      }

      &-content {
        .content {
          font-size:28px;
          line-height:29px;
          letter-spacing:normal;
        }
      }
    }
  }

  //////////
  &-2 {

    padding:192px 0 73px;

    &:before {
      display:none;
    }

    &:after {
      background:url(imgs/screen-2r.png) right bottom no-repeat;
    }

    .strip {
      &-inner {
        width:75%;
      }

      &-title {
        text-align:right;

        margin:0 120px 84px -120px;

        .text {
        }

        .last-line {
          @extend .pr;

          margin-right:-120px;
        }
      }

      &-content {
        width:72%;
        margin-top:40px;

        .content {
        }
      }
    }
  }

  //////////
  &-3 {

    padding:129px 0 80px;

    .strip-logo {
      left:auto;
      right:0;

      margin:-65px -72px 0 0;
    }

    &:before {
      background-image:url(imgs/screen-3l.png);
    }

    &:after {
      background-image:url(imgs/screen-3r.png);
    }

    .strip {
      &-inner {
        @extend .flr;

        width:69%;
        margin-right:-100px;
      }

      &-title {
        .text {
        }
      }

      &-content {
        @extend .flr;

        margin:110px 0 0;
        width:77%;

        .content {
        }
      }
    }
  }

  //////////
  &-4 {

    padding:205px 0 50px;

    .strip-logo {
      margin-top:-145px;
    }

    &:before {
      background-image:url(imgs/screen-4l.png);
    }

    &:after {
      background-image:url(imgs/screen-4r.png);
    }

    .strip {
      &-inner {
      }

      &-title {
        margin:0;

        .text {
          display:inline-block;

          font-size:404px;
          line-height:330px;
          vertical-align:top;

          top:-20px;
          left:-45px;

          i {
            font-style:normal;
            display:block;

            font-size:157px;
            line-height:155px;
            color:#fff;
          }
        }
      }

      &-content {
        width:51%;
        margin:10px 0 0;

        .content {
        }
      }
    }
  }

  //////////
  &-5 {

    padding:295px 0 95px;
    border-bottom:none;

    .strip-logo {
      margin-top:-230px;
    }

    &:before {
      background:url(imgs/screen-5l.png) left bottom no-repeat;
    }

    &:after {
      background:url(imgs/screen-5r.png) top right no-repeat;
    }

    .strip {
      &-inner {
      }

      &-title {
        .text-yellow {
          top:0;
        }
      }

      &-content {
        .content {
        }
      }
    }
  }

  &-logo {
    position:absolute;
    top:0;
    left:0;

    margin:-130px 0 0 -130px;
  }
}

@import 'strips-zmedia';