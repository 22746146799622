$green_color:#11e882;

$lg-width:1800px;
$sm-width:1100px;
$sm-768-width:768px;
$xs-width:590px;

@mixin font-face($name, $path, $fw, $fs, $charset: false) {
  @font-face {
    font-family:$name;

    src:url('fonts/#{$path}/#{$path}.eot');
    src:url('fonts/#{$path}/#{$path}.eot?#iefix') format('embedded-opentype'),
    url('fonts/#{$path}/#{$path}.woff') format('woff'),
    url('fonts/#{$path}/#{$path}.ttf') format('truetype');

    font-stretch:normal;
    font-weight:$fw;
    font-style:$fs;

    @if $charset {
      unicode-range:$charset;
    }
  }
}

@mixin border-radius($radius) {
  -webkit-border-radius:$radius;
  -moz-border-radius:$radius;
  border-radius:$radius;
}

@mixin box-shadow($offsetX, $offsetY, $blur, $color) {
  -webkit-box-shadow:$offsetX $offsetY $blur $color;
  -moz-box-shadow:$offsetX $offsetY $blur $color;
  box-shadow:$offsetX $offsetY $blur $color;
}

@mixin css-transition($transitionName, $time, $function) {
  -webkit-transition:$transitionName $time $function;
  -moz-transition:$transitionName $time $function;
  -o-transition:$transitionName $time $function;
  -ms-transition:$transitionName $time $function;
  transition:$transitionName $time $function;
}

@mixin placeholder-color($color) {
  &::-webkit-input-placeholder {
    color:$color;
  }
  &::-moz-placeholder {
    color:$color;
  }
  &:-moz-placeholder {
    color:$color;
  }
  &:-ms-input-placeholder {
    color:$color;
  }
}