@import 'variables';

@media all and (max-width:$lg-width) {

  .rules {

    padding-left:20px;
    padding-right:20px;

    &-header {
      font-size:56px;
      line-height:58px;

      span {
        font-size:75px;
        top:5px;
      }
    }

    &-item {

      padding:15px 30px 15px 125px;

      &__number {

        font-size:56px;
        line-height:58px;

        margin-top:-26px;

        i {
          font-size:26px;
          line-height:1;

          top:15px;
          left:-5px;
        }
      }

      &__desc {
        font-size:62px;
        line-height:65px;
        letter-spacing:normal;
      }
    }
  }
}

@media all and (max-width:$sm-width) {
  .rules-page {
    .body-wrapper {
      background:#000;
    }
  }
}

@media all and (max-width:$xs-width) {

  .rules {

    &-header {
      font-size:31px;
      line-height:32px;

      padding-bottom:20px;

      span {
        font-size:37px;
        top:2px;
      }
    }

    &-item {

      padding:15px 0;

      &__number {
        display:none;
      }

      &__desc {
        font-size:31px;
        line-height:32px;
      }
    }
  }
}