@import 'variables';

.footer {
  background:#fff;

  padding:36px 0;

  &-inner {
    @extend .pr;

    max-width:1730px;
    margin:0 auto;

    overflow:hidden;
  }

  &-menu {
    font-size:0;
    line-height:0;
    white-space:nowrap;

    margin:25px 345px 0 230px;

    li {
      display:inline-block;
      vertical-align:top;

      margin-left:50px;

      font-family:OpenSans, sans-serif;
      text-transform:uppercase;
      line-height:39px;
      font-weight:700;
      font-size:31px;

      &:first-of-type {
        margin-left:0;
      }

      a {
        text-decoration:none;
        color:#747474;

        @extend .pr;

        &:after {
          content:'';

          display:block;
          width:100%;
          height:2px;

          position:absolute;
          bottom:4px;
          left:0;

          background:#747474;
          font:0/0 d;
        }

        &:hover {
          color:#000;

          &:after {
            background:#000;
          }
        }
      }
    }
  }

  &-contacts {
    @extend .fll, .tac;

    &__title {
      text-transform:uppercase;
      font-weight:600;
      font-size:31px;
      line-height:1;
      color:#000;

      padding:0 0 20px
    }

    .socials {
      float:none;
      width:auto;
    }
  }

  &-language-new {

    margin-top:8px;
    float:right;

    a {
      display:inline-block;

      font-size:0;
      line-height:82px;
      color:#747474;

      .icon {
        border:2px solid #747474;
        vertical-align:top;

        @include border-radius(41px);

        margin-right:20px;
      }

      span {
        display:inline-block;
        vertical-align:middle;
        text-transform:uppercase;

        max-width:230px;

        font-size:31px;
        line-height:35px;
        font-weight:600;
      }

      &:hover {

        .icon {
          border-color:#000;
        }

        color:#000;
      }
    }
  }
}

@import 'footer-zmedia';