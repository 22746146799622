@import 'variables';

@include font-face(OpenSans, OpenSans, 400, normal, U+0020-FFFD);
@include font-face(OpenSans, OpenSans-Semibold, 600, normal, U+0020-FFFD);
@include font-face(OpenSans, OpenSans-Bold, 700, normal, U+0020-FFFD);
@include font-face(RockwellCondensed, RockwellCondensed, 400, normal, U+0020-FB02);

@import 'reset';
@import 'icons';
@import 'common';
@import 'langs';
@import 'faq';
@import 'rules';

@import 'header';
@import 'strips';
@import 'footer';