@import 'variables';

.header {
  padding:60px 0 35px;

  &:after {
    content:'';

    display:block;
    clear:both;
  }

  .logo {
    margin-left:-130px;
  }

  &_global {
    padding-left:100px;
    padding-right:100px;

    .logo {
      margin:0;

      &-xs {
        display:none;
      }
    }
  }
}

body {

  .header {

    &_fixed {
      display:none;
      width:100%;

      padding:30px 0 3px;

      background:#000;

      position:fixed;
      top:0;
      left:0;

      z-index:15;

      text-align:center;

      .logo {
        display:none;

        &-xs {
          display:inline-block;
          vertical-align:top;
        }
      }

      .app-buttons {

        display:block;

        &_wide {
          padding:13px 0;
          margin:20px 0 0;

          border-bottom:none;

          a {
            margin-bottom:0;

            img {
            }
          }
        }
      }
    }
  }
}

@import 'header-zmedia';